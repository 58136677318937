import React from 'react';
import { bool, number, object, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import { H1 } from '../PageBuilder/Primitives/Heading';
import PageBuilder, { SectionBuilder } from '../PageBuilder/PageBuilder';

import FallbackPage, { fallbackSections } from './FallbackPage';
import { ASSET_NAME } from './CMSLandingPage.duck';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withViewport } from '../../util/contextHelpers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { intlShape } from '../../util/reactIntl';

// This "content-only" component can be used in modals etc.
const CMSLandingPageContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : fallbackSections;

  return (
    <SectionBuilder
      {...sectionsData}
      options={{
        fieldComponents: {
          heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
        },
        isInsideContainer: true,
      }}
    />
  );
};

// Presentational component for PrivacyPolicyPage
const CMSLandingPageComponent = props => {
  const { pageAssetsData, inProgress, error, additionalData , listings, intl, viewport, isAuthenticated,
    currentUser,} = props;
  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      options={{additionalData:additionalData, listings:listings,intl:intl, viewport:viewport, isAuthenticated : isAuthenticated}}
      error={error}
      fallbackPage={<FallbackPage />}
    />
  );
};

CMSLandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentPageResultIds,additionalData } = state.CMSLandingPage;
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const pageListings = getListingsById(state, currentPageResultIds);

  let listings =
    pageListings &&
    pageListings.filter(
      item =>
        item.attributes &&
        item.attributes.title &&
        item.attributes.title.includes('Vendor Listing') !== true
    );
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error ,scrollingDisabled: isScrollingDisabled(state),
    listings,
    currentUser,
    isAuthenticated,
    additionalData};
};
const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});


  const CMSLandingPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withViewport
  )(CMSLandingPageComponent); 

const CMS_LANDING_PAGE_ASSET_NAME = ASSET_NAME;
export { CMS_LANDING_PAGE_ASSET_NAME, CMSLandingPageComponent, CMSLandingPageContent };

export default CMSLandingPage;
